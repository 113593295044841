import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { navigate } from "gatsby";
import React, { Fragment, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import SignUpForm, { SignUpRequestData } from "../../components/Forms/SignUp";
import LanguageSelector from "../../components/LanguageSelector";
import Logo, { LogoTypes } from "../../components/Logo";
import Modal from "../../components/Modal";
import { useUserService } from "../../services/user";
import { shared } from "../../utils/global/shared";
import { postNewUser } from "../../utils/SignUp";
import * as styles from "./style.module.scss";

const SignUp = (): JSX.Element | null => {
    const { t } = useTranslation();
    const [state, setState] = useState({
        acceptableUsePolicy: false,
        jointControllerPolicy: false,
        termsOfUse: false,
    });
    const [openDialog, setOpenDialog] = useState(false);
    const [formData, setFormData] = useState({});
    const [error, setError] = useState(false);
    const { user } = useUserService();

    if (user) {
        navigate("/dashboard/");
        return null;
    }

    const handleSubmit = (data: SignUpRequestData) => {
        setOpenDialog(true);
        setFormData(data);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    const closeErrorDialog = () => {
        setError(false);
    };

    const sendUser = async () => {
        const licenses = {
            acceptableUsePolicy: Date.now(),
            jointControllerPolicy: Date.now(),
            termsOfUse: Date.now(),
        };
        // TODO:  Do the service call instead
        try {
            await postNewUser({
                ...formData,
                licenseAgreements: JSON.stringify(licenses),
            });

            navigate("/");
        } catch (error) {
            setState({
                acceptableUsePolicy: false,
                jointControllerPolicy: false,
                termsOfUse: false,
            });
            setOpenDialog(false);
            setError(true);
        }
    };

    return (
        <Fragment>
            <AppBar
                className={classNames(styles.header, styles.override)}
                elevation={0}
                position="static"
            >
                <Toolbar variant="dense">
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Grid item>
                            <Logo url="/" type={LogoTypes.SOMNILOG}></Logo>
                        </Grid>
                        <Grid item xs>
                            <LanguageSelector
                                fullWidth
                                selectClasses={classNames(
                                    styles.select,
                                    styles.override,
                                )}
                            />
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            <Container maxWidth="xs">
                <Grid container justifyContent="center" direction="column">
                    <Grid item>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Box py={3}>
                                <Logo url="/" type={LogoTypes.BRAND}></Logo>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <SignUpForm onSubmit={handleSubmit} />
                    </Grid>
                    <Grid item>
                        <Box py={4}>
                            <Container maxWidth="xs" fixed>
                                <Grid container justifyContent="center">
                                    <Grid item>
                                        <Typography variant="body2">
                                            <Trans i18nKey="Footer">
                                                Contact us at
                                                <Link
                                                    href={`mailto:${shared.email}`}
                                                    underline="hover"
                                                >
                                                    {shared.email}
                                                </Link>
                                            </Trans>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>
                    </Grid>
                </Grid>

                <Modal closeDialog={handleClose} openDialog={openDialog}>
                    <Grid
                        container
                        direction="column"
                        className={styles.container}
                        spacing={2}
                    >
                        <Grid item>
                            <Typography variant="body2">
                                {t("License agreement")}{" "}
                                <Link
                                    href={`mailto:${shared.email}`}
                                    underline="hover"
                                >
                                    {shared.email}
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <FormControl
                                component="fieldset"
                                className={styles.form}
                            >
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={state.termsOfUse}
                                                onChange={handleChange}
                                                name="termsOfUse"
                                            />
                                        }
                                        label={
                                            <Link
                                                href={t(
                                                    "Terms of Service Link",
                                                )}
                                                target="_blank"
                                                underline="hover"
                                            >
                                                {t("Terms of Service")}
                                            </Link>
                                        }
                                    ></FormControlLabel>
                                </FormGroup>
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    state.jointControllerPolicy
                                                }
                                                onChange={handleChange}
                                                name="jointControllerPolicy"
                                            />
                                        }
                                        label={
                                            <Link
                                                href={t(
                                                    "Joint Controller Policy Link",
                                                )}
                                                target="_blank"
                                                underline="hover"
                                            >
                                                {t("Joint Controller Policy")}
                                            </Link>
                                        }
                                    ></FormControlLabel>
                                </FormGroup>
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    state.acceptableUsePolicy
                                                }
                                                onChange={handleChange}
                                                name="acceptableUsePolicy"
                                            />
                                        }
                                        label={
                                            <Link
                                                href={t(
                                                    "Acceptable Use Policy Link",
                                                )}
                                                target="_blank"
                                                underline="hover"
                                            >
                                                {t("Acceptable Use Policy")}
                                            </Link>
                                        }
                                    ></FormControlLabel>
                                </FormGroup>
                                <Box py={2} width={1}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={sendUser}
                                        disabled={
                                            !Object.values(state).every(
                                                item => item === true,
                                            )
                                        }
                                    >
                                        {t("Submit")}
                                    </Button>
                                </Box>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Modal>

                <Dialog open={error} onClose={closeErrorDialog}>
                    <DialogTitle>{t("ErrorTitle")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t("ErrorText")}{" "}
                            <Link
                                href={`mailto:${shared.email}`}
                                underline="hover"
                            >
                                {shared.email}
                            </Link>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeErrorDialog}>{t("Close")}</Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </Fragment>
    );
};

export default SignUp;
