import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { navigate } from "gatsby";
import React, { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";

export interface SignUpRequestData {
    email: string;
    firstName: string;
    jobTitle: string;
    lastName: string;
    institution: string;
    vat: string;
}

interface Props {
    onSubmit: (data: SignUpRequestData) => void;
    busy?: boolean;
}

const isNullOrWhiteSpace = (value: string | null) => {
    return value === null || value.match(/^ *$/) !== null;
};

const checkOnValidEmail = (email: string) => {
    const validRegex =
        /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return !!email.match(validRegex);
};

const SignUpForm = ({ onSubmit, busy }: Props): JSX.Element => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [institution, setInstitution] = useState("");
    const [vat, setVat] = useState("");
    const [submitted, setSubmitted] = useState(false);

    const checkValidation = () => {
        return (
            checkOnValidEmail(email) &&
            !isNullOrWhiteSpace(email) &&
            !isNullOrWhiteSpace(firstName) &&
            !isNullOrWhiteSpace(lastName) &&
            !isNullOrWhiteSpace(jobTitle)
        );
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        setSubmitted(true);
        if (checkValidation()) {
            onSubmit({
                email,
                firstName,
                institution,
                jobTitle,
                lastName,
                vat,
            });
        }
        event.preventDefault();
    };

    return (
        <>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container>
                    <Box p={2} width={1}>
                        <TextField
                            id="email"
                            label={t("Email")}
                            value={email}
                            required={true}
                            type="email"
                            error={
                                submitted &&
                                (!checkOnValidEmail(email) ||
                                    isNullOrWhiteSpace(email))
                            }
                            onChange={event => setEmail(event.target.value)}
                        />
                    </Box>
                    <Box p={2} width={1}>
                        <TextField
                            id="firstName"
                            label={t("FirstName")}
                            value={firstName}
                            required={true}
                            error={submitted && isNullOrWhiteSpace(firstName)}
                            onChange={event => setFirstName(event.target.value)}
                        />
                    </Box>
                    <Box p={2} width={1}>
                        <TextField
                            id="lastName"
                            label={t("SurName")}
                            value={lastName}
                            required={true}
                            error={submitted && isNullOrWhiteSpace(lastName)}
                            onChange={event => setLastName(event.target.value)}
                        />
                    </Box>
                    <Box p={2} width={1}>
                        <TextField
                            id="jobTitle"
                            label={t("Profession")}
                            value={jobTitle}
                            required={true}
                            error={submitted && isNullOrWhiteSpace(jobTitle)}
                            onChange={event => setJobTitle(event.target.value)}
                        />
                    </Box>
                    <Box p={2} width={1}>
                        <TextField
                            id="institution"
                            label={t("Institution")}
                            value={institution}
                            required={true}
                            error={submitted && isNullOrWhiteSpace(institution)}
                            onChange={event =>
                                setInstitution(event.target.value)
                            }
                        />
                    </Box>
                    <Box p={2} width={1}>
                        <TextField
                            id="VAT"
                            label={t("VAT")}
                            value={vat}
                            error={submitted && isNullOrWhiteSpace(vat)}
                            onChange={event => setVat(event.target.value)}
                        />
                    </Box>
                    <Box p={2} width={1}>
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                            disabled={busy}
                        >
                            {t("Submit")}
                        </Button>
                    </Box>
                    <Box p={2} width={1}>
                        <Button
                            color="secondary"
                            variant="contained"
                            fullWidth
                            disabled={busy}
                            onClick={() => navigate("/")}
                        >
                            {t("Go back")}
                        </Button>
                    </Box>
                </Grid>
            </form>
        </>
    );
};

export default SignUpForm;
